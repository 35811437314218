import axios from 'axios';
import { throttleAdapterEnhancer } from 'axios-extensions';
import { refreshToken } from '~/shared/api/auth.api';
import { objCheckType } from '~/shared/utils/computeFunc';
import { $toast } from '~/main';
import { parseErrorsFromResponse } from '~/shared/utils/parsers';
import router from '~/app/router';

const throttleConfig = {
  threshold: 1000,
};

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND}/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    apiKey: `${process.env.VUE_APP_APIKEY}`,
  },
  adapter: throttleAdapterEnhancer(axios.defaults.adapter, throttleConfig),
});

const generateToaster = errors => {
  if (errors && objCheckType(errors, 'string')) {
    $toast.show({ title: 'Что-то пошло не так', subTitle: errors, type: 'error' });
  } else if (errors && Array.isArray(errors)) {
    errors.forEach(error => {
      $toast.show({ title: 'Что-то пошло не так', subTitle: error, type: 'error' });
    });
  }
};

const errorStatus = {
  403: function () {
    router.push({ name: 'error403' });
  },
  419: function () {
    router.back() || router.push('/');
  },
};

const errorInterceptor = async error => {
  if (error.response.status in errorStatus) {
    errorStatus[error.response.status]();
  } else {
    let errors = parseErrorsFromResponse(error);
    generateToaster(errors);
  }
  return Promise.reject(error);
};

const requestInterceptor = async config => {
  config.headers['Authorization'] = await refreshToken();
  return config;
};

const setAuthToken = token => {
  apiClient.defaults.headers['Authorization'] = `Bearer ${token}`;
};

const responseInterceptor = response => response;

apiClient.interceptors.request.use(requestInterceptor);
apiClient.interceptors.response.use(responseInterceptor, errorInterceptor);
export { apiClient, requestInterceptor, setAuthToken };
