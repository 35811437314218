import { createRouter, createWebHistory } from 'vue-router';
import { publicRoutes } from '~/app/router/public';
import { privateRoutes } from '~/app/router/private';
import { deleteToken, getMessaging, getToken } from 'firebase/messaging';
import { authModel } from '~/entities/auth';
import { appModel } from '~/entities/app';
import { hasAccessToApp, keycloak, login } from '~/shared/api/auth.api';

const router = createRouter({
  history: createWebHistory(),
  routes: privateRoutes.concat(publicRoutes),
});

router.beforeEach(async (to, from, next) => {
  const authStore = authModel.useStore();
  const appStore = appModel.useStore();
  document.title = 'Team' + (to?.meta?.title ? ' - ' + to.meta.title : '');
  const isPublic = to.matched.some(route => route.meta.public);

  if (!isPublic && !keycloak.authenticated) {
    await login();
  }

  if (!isPublic && !hasAccessToApp() && to.name !== 'forbidden') {
    next({ name: 'forbidden' });
  }

  if ('Notification' in window) {
    if (Notification.permission !== 'denied' && !hasAccessToApp()) {
      const messaging = getMessaging();
      getToken(messaging, {
        vapidKey: process.env.VUE_APP_FIREBASE_VAPID_KEY,
      }).then(currentToken => {
        if (currentToken) {
          deleteToken(messaging);
        }
      });
    }
  }

  if (hasAccessToApp()) {
    if (!authStore.user) {
      await authStore.getUserFunc();
    }
    if (!appStore.globals) {
      await appStore.getAppFunc();
    }
  }

  next();
});

router.onError(error => {
  if (/loading chunk (.*)? failed./i.test(error.message)) {
    alert('Возможно версия сайта устарела. Сейчас эта страница будет обновлена.');
    window.location.reload();
  }
});

export default router;
