import { defineStore } from 'pinia';
import { ref } from 'vue';
import { getUser } from '~/shared/api/auth.api';

export const useAuthStore = defineStore('auth', () => {
  const user = ref(null);
  const getUserFunc = async () => {
    const response = await getUser();
    user.value = response?.data.data;
  };

  return { getUserFunc, user };
});
